import React from 'react'
import { faSkull, faBlind } from '@fortawesome/free-solid-svg-icons'

import Layout from '@/components/Layout'
import PageTitle from '@/components/PageTitle'
import Seo from '@/components/Seo'
import SubpageList from '@/components/SubpageList'

const subPageList = [
  {
    title: 'Гробница аннигиляции',
    icon: faSkull,
    url: '/campaigns/toa',
  },
  {
    title: 'Из Бездны',
    icon: faBlind,
    url: '/campaigns/oota',
  },
]

const DndPage = () => (
  <Layout>
    <Seo
      title='Инструменты для опубликованых кампаний в Dungeons & Dragons'
      description='Из Бездны, Гробница аннигиляции'
    />
    <PageTitle>Инструменты для опубликованых кампаний в Dungeons & Dragons</PageTitle>
    <SubpageList list={subPageList}/>
  </Layout>
)

export default DndPage
